<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>低代码套餐管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="30" style="display: flex; margin-left: 50px">
        <el-button type="primary" @click="setPrivilege"
          >维护功能权限清单</el-button
        >
        <el-button type="primary" @click="addFlag">维护套餐类型</el-button>
        <el-button type="primary" @click="addVersion">创建套餐</el-button>
      </el-row>
      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ToolList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="packageKindName"
            label="套餐类型名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="packageName"
            label="套餐名称"
            min-width="150px"
          >
          </el-table-column>
          <el-table-column label="有效周期" min-width="150px" prop="payPeriodT">
          </el-table-column>

          <el-table-column label="状态" min-width="100px">
            <template slot-scope="scope">
              <el-tag :type="scope.row.state == 1 ? 'success' : 'danger'">{{
                scope.row.state == 1 ? "已启用" : "已禁用"
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            min-width="150px"
          ></el-table-column>
          <el-table-column label="操作" min-width="250px">
            <template slot-scope="scope">
              <el-button
                :type="scope.row.state == 1 ? 'danger' : 'success'"
                size="mini"
                @click="StatusPackage(scope.row)"
                >{{ scope.row.state == 1 ? "禁用" : "启用" }}</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="EditPackage(scope.row)"
                >修改</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="deletePackage(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <!--  创建套餐-->
      <el-dialog
        :close-on-click-modal="false"
        :title="titles"
        :visible.sync="createDialogVisible"
        width="30%"
        @close="createDialogClosed"
      >
        <el-form
          :model="createForm"
          :rules="createFormRules"
          ref="createFormRef"
          label-width="100px"
        >
          <el-form-item label="套餐类型：" prop="type">
            <ELselect
              v-model="createForm.type"
              :options="setFlagList"
              :optionKey="TypeStatuskeys"
              Splaceholder="请选择"
              @clear="getList"
              @change="changeType"
            ></ELselect>
          </el-form-item>
          <el-form-item label="套餐名称：" prop="name">
            <el-input
              v-model.trim="createForm.name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="有效周期：" prop="timedTAE">
            <ELselect
              v-model="createForm.timedTAE"
              :options="TypeStatus1"
              :optionKey="TypeStatuskeys1"
              Splaceholder="请选择"
              @clear="getList"
            ></ELselect>
          </el-form-item>

          <el-form-item
            label="启用状态："
            prop="status"
            style="text-align: left"
            v-show="!flagEdit"
          >
            <el-radio-group v-model="createForm.status">
              <el-radio
                v-for="item in radioList"
                :key="item.menuIndex"
                :label="item.menuIndex"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="createDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmAdd">确定</el-button>
        </span>
      </el-dialog>

      <!--  功能权限清单-->
      <el-dialog
        :close-on-click-modal="false"
        title="功能权限清单"
        :visible.sync="setDialogVisible"
      >
        <el-button type="primary" style="display: flex" @click="addPrivilege"
          >新增功能权限</el-button
        >

        <el-row
          :gutter="30"
          style="display: flex; margin-right: 1px; margin-left: 1px"
        >
          <el-table
            :data="setProductList"
            :header-cell-style="{
              textAlign: 'center',
              background: '#FAFAFA',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '500',
            }"
            :cell-style="{
              textAlign: 'center',
            }"
            tooltip-effect="dark"
          >
            <el-table-column type="index"></el-table-column>
            <el-table-column
              prop="name"
              label="功能权限"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="key"
              label="Key值"
              align="center"
            ></el-table-column>
            <el-table-column label="类型" align="center">
              <template slot-scope="scope">
                {{
                  scope.row.type == 0 ? radioList1[0].name : radioList1[1].name
                }}
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="150px">
              <template slot-scope="scope">
                <el-button
                  :type="scope.row.isUse ? 'info' : 'primary'"
                  :disabled="scope.row.isUse ? true : false"
                  size="mini"
                  @click="Edits(scope.row)"
                  >修改</el-button
                >
                <el-button
                  :type="scope.row.isUse ? 'info' : 'danger'"
                  size="mini"
                  :disabled="scope.row.isUse ? true : false"
                  @click="deletes(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-dialog>

      <el-dialog
        :close-on-click-modal="false"
        :title="titles"
        :visible.sync="createPrivilegeDialogVisible"
        width="30%"
        @close="createPrivilegeDialogClosed"
      >
        <el-form
          :model="createPrivilege"
          :rules="createPrivilegeFormRules"
          ref="createPrivilegeFormRef"
          label-width="100px"
        >
          <el-form-item label="key值：" prop="keyValue">
            <el-input
              v-model.trim="createPrivilege.keyValue"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="功能名称：" prop="name">
            <el-input
              v-model.trim="createPrivilege.name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型：" prop="type" style="text-align: left">
            <el-radio-group v-model="createPrivilege.type">
              <el-radio
                v-for="item in radioList1"
                :key="item.menuIndex"
                :label="item.menuIndex"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="createPrivilegeDialogVisible = false"
            >取消</el-button
          >
          <el-button type="primary" @click="PrivilegeAdd">确定</el-button>
        </span>
      </el-dialog>

      <!-- 套餐类型 -->
      <el-dialog
        :close-on-click-modal="false"
        title="套餐类型"
        :visible.sync="FlagDialog"
      >
        <el-button type="primary" style="display: flex" @click="addFlagDialog"
          >新增套餐类型</el-button
        >

        <el-row
          :gutter="30"
          style="display: flex; margin-right: 1px; margin-left: 1px"
        >
          <el-table
            :data="setFlagList"
            :header-cell-style="{
              textAlign: 'center',
              background: '#FAFAFA',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '500',
            }"
            :cell-style="{
              textAlign: 'center',
            }"
            tooltip-effect="dark"
          >
            <el-table-column type="index"></el-table-column>
            <el-table-column
              prop="packageKindName"
              label="套餐类型名称"
              min-width="100px"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="packageKindId"
              label="套餐类型标识"
              align="center"
              min-width="100px"
            ></el-table-column>
            <el-table-column label="操作" min-width="300px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="upMove(scope.row)"
                  :disabled="scope.row.index === 0 ? true : false"
                  class="buttons"
                  >上移</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="downMove(scope.row)"
                  :disabled="
                    scope.row.index >= setFlagList.length - 1 ? true : false
                  "
                  class="buttons"
                  >下移</el-button
                >
                <el-button
                  :type="scope.row.isUse ? 'info' : 'primary'"
                  size="mini"
                  :disabled="scope.row.isUse ? true : false"
                  @click="EditFlags(scope.row)"
                  class="buttons"
                  >修改</el-button
                >

                <el-button
                  type="primary"
                  size="mini"
                  @click="setFunctionFlags(scope.row)"
                  class="buttons"
                  >权限配置</el-button
                >
                <el-button
                  :type="scope.row.isUse ? 'info' : 'danger'"
                  size="mini"
                  :disabled="scope.row.isUse ? true : false"
                  @click="DeleteFlags(scope.row)"
                  class="buttons"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-dialog>

      <el-dialog
        :close-on-click-modal="false"
        :title="titles"
        :visible.sync="setFlagDialog"
        width="30%"
        @close="setFlagDialogClosed"
      >
        <el-form
          :model="createFlagForm"
          :rules="createFlagFormRules"
          ref="createFlagFormRef"
          label-width="120px"
        >
          <el-form-item label="套餐类型标识：" prop="type">
            <el-input
              v-model.trim="createFlagForm.type"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="套餐类型名称：" prop="name">
            <el-input
              v-model.trim="createFlagForm.name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="setFlagDialog = false">取消</el-button>
          <el-button type="primary" @click="setFlagaDD">确定</el-button>
        </span>
      </el-dialog>

      <!--  套餐功能权限-->
      <el-dialog
        :close-on-click-modal="false"
        :title="titles"
        :visible.sync="setFlagDialog1"
        width="40%"
      >
        <el-row
          :gutter="30"
          style="display: flex; margin-right: 1px; margin-left: 1px"
        >
          <el-table
            :data="setProductList"
            :header-cell-style="{
              textAlign: 'center',
              background: '#FAFAFA',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '500',
            }"
            :cell-style="{
              textAlign: 'center',
            }"
            tooltip-effect="dark"
            @selection-change="handleAddChange"
            @row-click="handleAddChange1"
            ref="multipleTable"
          >
            <el-table-column type="selection" width="50px"> </el-table-column>
            <el-table-column type="index" min-width="50px"></el-table-column>
            <el-table-column
              prop="name"
              label="功能权限"
              align="center"
              min-width="100px"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="权限配置" align="center" min-width="200px">
              <template slot-scope="scope">
                <div v-if="scope.row.type == 0">
                  <el-radio-group v-model="scope.row.radioVal">
                    <el-radio
                      v-for="item in radioList2"
                      :key="item.menuIndex"
                      :label="item.menuIndex"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
                <div v-else>
                  <el-input-number
                    v-model.trim="scope.row.inputVal"
                    :controls="false"
                    size="mini"
                    style="width: 100px; margin-right: 10px"
                    :precision="0"
                    :disabled="scope.row.checked ? true : false"
                    :min="0"
                  ></el-input-number>
                  <!-- <el-input
                    size="mini"
                    style="width: 100px; margin-right: 10px"
                    type="nu"
                    v-model.trim="scope.row.inputVal"
                    :disabled="scope.row.checked ? true : false"
                  ></el-input> -->
                  <el-checkbox v-model="scope.row.checked">不限</el-checkbox>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setFlagDialog1 = false">取消</el-button>
          <el-button type="primary" @click="confirmSets">确定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import ELselect from '@/components/myself/el_select'
import { getToolPackageId, toolPackagePage, updateToolPackageStatus, addPackageKind, getToolPackageKindList, getToolPackageKind, deletePackageKind, addPermission, getPermission, deletePermission, updatePermission, getToolPermission, addPackage, deletePackage } from '@/api/toolmanager/toolVersion.js'
import { deleteDialog } from '@/libs/confirmDialog'
import global_param from '@/libs/globalVar/global_param'
import { payPeriodSet } from '@/libs/globalVar/computePeriod'

export default {
  components: { ELselect },

  data () {
    return {
      queryinfo: {
        name: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      total1: 0,
      ToolList: [],
      createDialogVisible: false,
      createForm: {
        name: '',
        type: '',
        status: 0,
        timedTAE: ''
      },
      createFormRules: {
        type: [
          { required: true, message: '标识不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 1, max: 50, message: '长度需在1-50个字符间', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态不能为空', trigger: 'blur' }
        ],
        timedTAE: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
      },
      // productList: [],

      // 
      titles: '',
      setDialogVisible: false,
      setProductList: [],
      createPrivilegeDialogVisible: false,
      createPrivilege: {
        keyValue: '',
        name: '',
        type: 0
      },
      createPrivilegeFormRules: {
        keyValue: [
          { required: true, message: '标识不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 1, max: 50, message: '长度需在1-50个字符间', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '状态不能为空', trigger: 'blur' }
        ],
      },
      radioList: [
        { menuIndex: 0, name: '未启用' },
        { menuIndex: 1, name: '启用' },
      ],
      radioList1: [
        { menuIndex: 0, name: '布尔型' },
        { menuIndex: 1, name: '数值型' },
      ],
      radioList2: [
        { menuIndex: 1, name: '支持' },
        { menuIndex: 0, name: '不支持' },
      ],
      // radio: 0,
      flagEdit: false,
      FlagID: '',
      KindID: '',
      // 

      // TypeStatus: [],
      TypeStatuskeys: {
        value: 'packageKindId',
        label: 'packageKindName',
        label2: ''
      },
      TypeStatus1: global_param.payPeriod,
      TypeStatuskeys1: {
        value: 'value',
        label: 'label',
        label2: ''
      },

      FlagDialog: false,
      setFlagList: [],
      setFlagDialog: false,
      createFlagForm: {
        type: '',
        name: ''
      },
      createFlagFormRules: {
        type: [
          { required: true, message: '标识不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' }
        ],
      },

      setFlagDialog1: false,
      // 
      multSelectAdd: [],
      flagname: '',
      flagnames: false,


    }
  },
  mounted () { this.getList() },
  methods: {
    async getList () {
      const res = await toolPackagePage(this.queryinfo.pageNum, this.queryinfo.pageSize)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ToolList = res.data.data.list
      this.total = res.data.data.total

      this.ToolList.forEach(item => {
        this.$set(item, 'payPeriodT', payPeriodSet(item.payPeriod))
      });
    },
    addVersion () {
      this.createDialogVisible = true
      this.flagEdit = false
      this.titles = '创建套餐'
      this.gitFlagList()
    },
    createDialogClosed () {
      this.$refs.createFormRef.resetFields()
    },
    changeType (para) {
      this.KindID = para
    },
    handleAddChange (val) {
      this.multSelectAdd = val
    },
    handleAddChange1 (val) {
      this.multSelectAdd.forEach(item => {
        if (item.id === val.id) {
          item = val
        }
      });
    },
    confirmAdd () {
      this.$refs.createFormRef.validate(async valid => {
        if (valid) {
          // 0：禁用，1：启用； 0：不限，1：限制,1：支持，0：不支持
          let quiese = {}
          if (!this.flagEdit) {
            quiese = { packageKindId: this.createForm.type, packageName: this.createForm.name, payPeriod: this.createForm.timedTAE, state: this.createForm.status, }

          } else {
            quiese = { id: this.FlagID, packageKindId: this.KindID, packageName: this.createForm.name, payPeriod: this.createForm.timedTAE, state: this.createForm.status }

          }
          const res = await addPackage(quiese)
          if (res.status !== 200) return this.$message.error('操作失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.createDialogVisible = false
          this.getList()
        }
      })
    },

    async StatusPackage (para) {
      const res = await updateToolPackageStatus(para.id)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.getList()
    },
    async EditPackage (para) {
      this.createDialogVisible = true
      this.flagEdit = true
      this.FlagID = para.id
      this.titles = '修改套餐'
      const res = await getToolPackageId(para.id)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      // console.log('===============', res.data.data)
      this.KindID = res.data.data.packageKindId
      this.createForm.type = res.data.data.packageKindName
      this.createForm.name = res.data.data.packageName
      this.createForm.timedTAE = res.data.data.payPeriod
      this.createForm.status = Number(res.data.data.state)
      this.gitFlagList()

    },
    async deletePackage (para) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await deletePackage(para.id)
        if (res.status !== 200) return this.$message.error('操作失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.$message.success('删除成功')
        this.getList()
      }
    },

    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },



    // ************** 权限列表
    setPrivilege () {
      this.setDialogVisible = true
      this.gitPrivilegeList()
    },

    async gitPrivilegeList (para) {
      this.setProductList = []
      const res = await getPermission(para)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.setProductList = res.data.data

      if (para) {
        // 显示值
        this.$nextTick(function () {
          this.setProductList.forEach(item => {
            this.$refs.multipleTable.toggleRowSelection(item, item.isUse)
            if (item.type === 1) {
              if (item.confine === 1) {
                this.$set(item, 'inputVal', item.value)
                this.$set(item, 'checked', false)
              } else {
                this.$set(item, 'inputVal', '')
                this.$set(item, 'checked', true)
              }

            } else {
              if (typeof item.value !== 'undefined') {
                this.$set(item, 'radioVal', Number(item.value))
              } else {
                this.$set(item, 'radioVal', 1)
              }
            }
          })
        })
      }

    },
    addPrivilege () {
      this.createPrivilegeDialogVisible = true
      this.titles = '新增功能权限'
    },
    createPrivilegeDialogClosed () {
      this.$refs.createPrivilegeFormRef.resetFields()
      this.flagEdit = false
    },

    PrivilegeAdd () {
      this.$refs.createPrivilegeFormRef.validate(async valid => {
        if (valid) {
          if (!this.flagEdit) {
            let quiese = { key: this.createPrivilege.keyValue, name: this.createPrivilege.name, type: this.createPrivilege.type }
            // let quiese = { key: this.createPrivilege.keyValue, name: this.createPrivilege.name, type: this.createPrivilege.type, value: this.radioList1[this.createPrivilege.type].name }
            const res = await addPermission(quiese)
            if (res.status !== 200) return this.$message.error('操作失败')
            if (res.data.status !== 200) return this.$message.error(res.data.message)
            this.$message.success('新增成功')
          } else {
            let quiese = { id: this.FlagID, key: this.createPrivilege.keyValue, name: this.createPrivilege.name, type: this.createPrivilege.type }
            const res = await updatePermission(quiese)
            if (res.status !== 200) return this.$message.error('操作失败')
            if (res.data.status !== 200) return this.$message.error(res.data.message)
            this.$message.success('修改成功')
          }
          this.createPrivilegeDialogVisible = false
          this.gitPrivilegeList()
        }
      })
    },
    async Edits (para) {
      this.createPrivilegeDialogVisible = true
      this.flagEdit = true
      this.FlagID = para.id
      this.titles = '修改功能权限'
      const res = await getToolPermission(para.id)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.createPrivilege.keyValue = res.data.data.keyId
      this.createPrivilege.name = res.data.data.keyName
      this.createPrivilege.type = res.data.data.keyType
    },

    async deletes (para) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await deletePermission(para.id)
        if (res.status !== 200) return this.$message.error('操作失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.$message.success('删除成功')
        this.gitPrivilegeList()
      }
    },


    // ************** 种类列表
    async gitFlagList () {
      const res = await getToolPackageKindList()
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.setFlagList = res.data.data
    },
    addFlag () {
      this.FlagDialog = true
      this.gitFlagList()
    },

    addFlagDialog () {
      this.setFlagDialog = true
      this.titles = '新增套餐类型'
    },
    setFlagaDD () {
      this.$refs.createFlagFormRef.validate(async valid => {
        if (valid) {
          let quiese = {}
          if (!this.flagEdit) {
            quiese = { packageKindId: this.createFlagForm.type, packageKindName: this.createFlagForm.name }
          } else {
            quiese = { id: this.FlagID, packageKindId: this.createFlagForm.type, packageKindName: this.createFlagForm.name }
          }
          const res = await addPackageKind(quiese)
          if (res.status !== 200) return this.$message.error('操作失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.setFlagDialog = false
          this.gitFlagList()
        }
      })
    },
    setFlagDialogClosed () {
      this.$refs.createFlagFormRef.resetFields()
      this.flagEdit = false
    },
    async EditFlags (para) {
      this.setFlagDialog = true
      this.flagEdit = true
      this.FlagID = para.id
      this.titles = '修改套餐类型'
      const res = await getToolPackageKind(para.id)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.createFlagForm.type = res.data.data.packageKindId
      this.createFlagForm.name = res.data.data.packageKindName
    },
    async DeleteFlags (para) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await deletePackageKind(para.id)
        if (res.status !== 200) return this.$message.error('操作失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.$message.success('删除成功')
        this.gitFlagList()
      }
    },

    async upMove (para) {
      // level: 1 上移, 0下移 
      const res = await addPackageKind({ id: para.id, level: 1 })
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('上移成功')
      this.gitFlagList()
    },
    async downMove (para) {
      const res = await addPackageKind({ id: para.id, level: 0 })
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('下移成功')
      this.gitFlagList()
    },

    async setFunctionFlags (para) {
      this.titles = '套餐类型' + para.packageKindName + '的权限设置'
      this.FlagID = para.id
      this.setFlagDialog1 = true

      this.gitPrivilegeList(para.packageKindId)
    },
    async confirmSets () {
      // 0：禁用，1：启用； 0：不限，1：限制,1：支持，0：不支持
      let PrivilegeList = []

      try {
        this.multSelectAdd.forEach(item => {
          if (item.type == 1) {
            if (!item.checked && item.inputVal === '') {
              this.flagname = item.name
              throw Error('error')
            }
            PrivilegeList.push({ id: item.id, value: item.checked ? '' : item.inputVal, confine: item.checked ? 0 : 1 })
          } else {
            PrivilegeList.push({ id: item.id, value: item.radioVal, confine: item.checked ? 0 : 1 })
          }
        })
        this.flagnames = false
      } catch (e) {
        this.flagnames = true
        console.log(e)
      }
      if (this.flagnames) return this.$message.error("请设置 " + this.flagname + " 的权限配置")

      let quiese = { id: this.FlagID, toolPermissionDTOList: PrivilegeList }
      const res = await addPackageKind(quiese)
      if (res.status !== 200) return this.$message.error('操作失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('操作成功')
      this.setFlagDialog1 = false
      this.gitFlagList()
    }
  }
}
</script>
<style lang="less" scoped>
.buttons {
  margin-bottom: 10px;
}
</style>